<script>
import i18n from "../../i18n";
import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";

import axios from 'axios';
import FaqRoot from "./FaqRoot.vue";

export default {
  watch: {
  '$i18n.locale': function() {
      this.locale = i18n.global.locale;
      this.loadingFaqList();
    },
  },
  page: {
    title: "FAQ",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      locale: i18n.global.locale,
      data: {
        faq: []
      }
    };
  },
  components: {
    Layout,
    FaqRoot
  },
  created: function () {
    this.loadingFaqList();
  },
  methods: {
    loadingFaqList() {
      axios.get('/api/faq/' + this.locale)
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">

        <div class="card rounded-0 bg-soft-primary mx-n4 mt-n4 border-top">
          <div class="px-4">
            <div class="row">
              <div class="col-xxl-5 align-self-center">
                <div class="py-4">
                  <h4 class="display-6">{{$t('t-faq-title')}}</h4>
                  <p class="fs-15 mt-3">{{$t('t-faq-description')}}</p>
                  <div class="hstack flex-wrap gap-2">
                    <router-link to="/contacts" class="btn btn-primary btn-label rounded-pill">
                      <i class="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> {{ $t('t-faq-button-contacts') }}
                    </router-link>
                    <a class="btn btn-info btn-label rounded-pill" href="https://twitter.com/busycast" target="_blank" rel="nofollow">
                      <i class="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-2"></i> {{ $t('t-faq-button-twitter') }}
                    </a>
                    <a class="btn btn-info btn-label rounded-pill" href="https://t.me/busycast" target="_blank" rel="nofollow">
                      <i class="ri-telegram-line label-icon align-middle rounded-pill fs-16 me-2"></i> {{ $t('t-faq-button-telegram') }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xxl-3 ms-auto">
                <div class="mb-n5 pb-1 faq-img d-none d-xxl-block">
                  <img src="@/assets/images/art/faq.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-evenly">
          <div class="col-lg-6" v-for="p in data.faq" v-bind:key="p">
            <FaqRoot v-bind:data="p"></FaqRoot>
          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>